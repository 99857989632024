import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../assets/themes";
import {
  calculateTimeLeft,
  checkVotingPeriod,
} from "../../utils/calculateTimeLeft";

const Timer = ({ submitDateEnd, voteDateEnd, setSubmitOn, setVoteOn }) => {
  const vote = checkVotingPeriod();
  const submit = +new Date(submitDateEnd) - +new Date();

  useEffect(() => {
    setSubmitOn(submit > 0);
    setVoteOn(vote > 0);
  });

  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(vote > 0, submit > 0)
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(vote > 0, submit > 0));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const types = ["DAYS", "HOURS", "MINUTES", "SECONDS"];
  return (
    <>
      <Text
        color={COLORS.secondary}
        fontSize={{ base: "24px", md: "32px", lg: "32px" }}
        lineHeight={{ base: "29px", md: "39px" }}
        fontFamily="Montserrat Bold"
        fontWeight={700}
        mb={"32px"}
      >
        {submit > 0 && "Time Left"}
        {submit < 1 && vote > 0 && "Voting Ends in:"}
      </Text>
      <Flex
        direction={"row"}
        justify="center"
        fontSize={{ base: "18px", md: "24px", lg: "36px" }}
        lineHeight={{ base: "29px", md: "44px" }}
        fontFamily="Montserrat"
        fontWeight={400}
        color={COLORS.secondary_black}
      >
        {types.map((item, index) => (
          <Flex
            align="center"
            key={index}
            fontFamily="Montserrat"
            fontWeight={400}
          >
            <Box>
              <Text>
                {timeLeft.left
                  ? ("0" + timeLeft[item.toLowerCase()]).slice(-2)
                  : "00"}
              </Text>
              <Text
                fontSize={{ base: "10px", md: "14px", lg: "14px" }}
                lineHeight={{ base: "16px", md: "28px" }}
                letterSpacing={"0.15px"}
              >
                {item}
              </Text>
            </Box>
            {index + 1 !== types.length && (
              <Text mx={{ base: "10px", lg: "30px" }} color={COLORS.black}>
                :
              </Text>
            )}
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default Timer;
