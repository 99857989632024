import axios from "axios";

let baseUrl = process.env.REACT_APP_BACKEND_URL;

export const uploadIdea = (data, params) => {
  let response = axios.post(`${baseUrl}/api/v1/idea/submit`, data, {
    params,
  });
  return response;
};
