import React from "react";
import {
  Flex,
  Text,
  Divider,
  Link,
  Box,
  Container,
  Stack,
  VisuallyHidden,
  useColorModeValue,
  chakra,
} from "@chakra-ui/react";
import { COLORS } from "../../assets/themes";
import Logo from "../ui/Logo";
import {
  FacebookIcon,
  GoogleIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "../Icons";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
      target="_blank"
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const Footer = (props) => {
  return (
    <Box bg={COLORS.primary} w="100%" align="center">
      <Flex
        bg={COLORS.primary}
        direction="column"
        color={COLORS.white}
        maxW="container.xl"
        alignSelf={"center"}
        justify="end"
        wrap="wrap"
        w={{ base: "100%", lg: "100%" }}
        p={8}
      >
        <Flex
          justify={["center", "center", "space-between", "space-between"]}
          direction={["column", "row", "row", "row"]}
          color={["footerText", "footerText"]}
          fontSize={["12px", "12px", "14px", "14px"]}
          w="100%"
          {...props}
        >
          <Flex
            align="center"
            justify="center"
            me={{ base: 2, md: "37px" }}
            mb={{ base: 2, md: "25px" }}
          >
            <Logo w="100px" color={["primary", "primary"]} />
          </Flex>
          <Container
            as={Stack}
            maxW={"6xl"}
            py={4}
            direction={{ base: "column", sm: "row" }}
            spacing={8}
            justify={{ base: "center", md: "space-between" }}
            align={{ base: "center", md: "center" }}
            fontFamily="Fira Sans"
          >
            <Stack
              direction={{ base: "column", sm: "row" }}
              spacing={{ base: 4, md: "54px" }}
            >
              <Link href={"/#about"}>About</Link>
              <Link href={"/#prize"}>Prize</Link>
              <Link href={"/#prevWinners"}>Previous Winners</Link>
              <Link href={"/#partners"}>Partners</Link>
              <Link href={"/#submit"}>Submit Idea</Link>
            </Stack>
          </Container>
        </Flex>
        <Divider my={2} />
        <Flex
          fontSize="14px"
          fontFamily="Graphik Normal"
          fontWeight={"400"}
          lineHeight="20px"
          justify="space-between"
          align={{ base: "center", md: "center" }}
          direction={["column", "column", "row", "row"]}
        >
          <Flex direction={["column", "column", "row", "row"]}>
            <Stack direction={"row"} spacing={6} pt={2}>
              <SocialButton
                label={"Instagram"}
                href={"https://www.instagram.com/safeonlineng"}
              >
                <InstagramIcon />
              </SocialButton>
              <SocialButton
                label={"Facebook"}
                href={"https://www.facebook.com/SafeonlineNGN"}
              >
                <FacebookIcon />
              </SocialButton>
            </Stack>
          </Flex>
          <Text mt={{ base: 4, md: 0 }}>2022 ©️ All Rights Reserved</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
