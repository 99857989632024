import { Box, Flex, Text } from "@chakra-ui/react";
import { COLORS } from "../../assets/themes";
import PrevWinnersImages from "../ui/PrevWinnerImages";

const PreviousWinners = () => {
  return (
    <Box
      w="100%"
      minH={{ base: "200px", md: "350px", lg: "394px" }}
      mx="auto"
      backgroundColor={COLORS.background}
      p={8}
      id="prevWinners"
      pt={20}
    >
      <Flex
        justify={["center", "center", "space-around", "space-around"]}
        direction="column"
        w="100%"
        mx="auto"
      >
        <Text
          color={COLORS.secondary}
          fontSize={{ base: "24px", md: "32px", lg: "32px" }}
          lineHeight={{ base: "29px", md: "39px" }}
          fontFamily="Montserrat Bold"
          fontWeight={700}
          mt="8px"
          w="100%"
          align="center"
        >
          Previous Winners
        </Text>
        <PrevWinnersImages />
      </Flex>
    </Box>
  );
};

export default PreviousWinners;
