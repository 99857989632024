import React from "react";
import { Flex, Text, Box, Image, Button, Link } from "@chakra-ui/react";
import { COLORS } from "../../assets/themes";
import safeOnline from "../../assets/img/safeonlineFB.png";
import frame from "../../assets/img/frame.png";
import alert from "../../assets/img/alert.png";
import booking from "../../assets/img/booking.png";
import checked from "../../assets/img/checked.png";
import fingerPrint from "../../assets/img/fingerprint.png";
import dot from "../../assets/img/dot.png";
import { checkVotingPeriod } from "../../utils/calculateTimeLeft";

const Main = () => {
  return (
    <Box
      align="center"
      position="relative"
      w="100%"
      bg={COLORS.primary}
      pt={{ base: "150px", lg: "200px" }}
      zIndex={1}
    >
      <Box className="main" id="main">
        <Flex
          w="100%"
          maxW={"1200px"}
          direction={{ base: "column-reverse", lg: "row" }}
          align="center"
          justify={{ base: "center", lg: "space-between" }}
          mt={5}
          pt={{ base: 2, md: 8 }}
          px={{ base: 10, lg: 0 }}
          pb={{ base: "250px", md: "250px" }}
          zIndex={200}
        >
          <Flex
            direction="column"
            justify={"space-between"}
            align="start"
            mt={{ base: "64px", lg: 0 }}
          >
            <Text
              color={COLORS.white}
              fontSize={{ base: "24px", md: "36px", lg: "36px" }}
              lineHeight={{ base: "29px", md: "44px" }}
              fontFamily="Montserrat"
              fontWeight={400}
              mb={"4px"}
              textAlign="start"
            >
              Safe Online Fest
            </Text>
            <Text
              color={COLORS.white}
              fontSize={{ base: "36px", md: "56px", lg: "56px" }}
              lineHeight={{ base: "43px", md: "68px" }}
              fontFamily="Montserrat Bold"
              fontWeight={700}
              mb={"16px"}
              textAlign="start"
            >
              Big Idea Contest
            </Text>
            <Text
              color={COLORS.white}
              fontSize={{ base: "14px", md: "18px", lg: "18px" }}
              lineHeight={{ base: "18px", md: "27px" }}
              fontFamily="Montserrat"
              fontWeight={400}
              mb={"32px"}
              maxW="588px"
              textAlign="start"
            >
              We are calling on schools to participate in the Safe Online Fest
              by submitting a BIG IDEA on Digital Literacy and Online Safety
            </Text>
            <Button
              variant="solid"
              fontFamily="Poppins"
              fontWeight={400}
              fontSize={"18px"}
              lineHeight={"27px"}
              bg={COLORS.white}
              color={COLORS.primary}
              py={"16px"}
              px={"57px"}
              borderRadius={"10px"}
              _hover={{
                opacity: 0.9,
                color: COLORS.primary,
              }}
            >
              <Link sx={{ textDecoration: "none" }} href="#submit">
                {checkVotingPeriod() ? "VOTE NOW" : "SUBMIT IDEA"}
              </Link>
            </Button>
          </Flex>
          <Image src={safeOnline} />
        </Flex>
      </Box>
      <Image
        src={frame}
        position={"absolute"}
        bottom={"-70px"}
        width="100%"
        height={{ base: 100, md: "155px" }}
      />
      <Box
        position={"absolute"}
        right={{ base: "10%", lg: "17%" }}
        bottom={{ base: "6%", lg: "20%" }}
        bg={COLORS.white}
        p="11px"
        borderRadius={"50%"}
      >
        <Image src={booking} />
      </Box>
      <Box
        position={"absolute"}
        left={{ base: "10%" }}
        bottom={{ base: "90%", lg: "20%" }}
        bg={COLORS.white}
        zIndex={-1}
        p="11px"
        borderRadius={"50%"}
        display={{ base: "none", lg: "flex" }}
      >
        <Image src={fingerPrint} />
      </Box>
      <Box
        position={"absolute"}
        right={{ base: "7%", lg: "50%" }}
        top={{ base: "50%", lg: "10%" }}
        bg={COLORS.white}
        zIndex={-3}
        p="11px"
        borderRadius={"50%"}
      >
        <Image src={checked} />
      </Box>
      <Box
        position={"absolute"}
        left={"17%"}
        bottom={"15%"}
        bg={COLORS.white}
        zIndex={-1}
        p="11px"
        display={{ base: "none", lg: "flex" }}
        borderRadius={"50%"}
      >
        <Image src={alert} />
      </Box>
      <Image
        src={dot}
        position={"absolute"}
        left={"40%"}
        top={"5%"}
        zIndex={-1}
      />
      <Image
        src={dot}
        h="16px"
        w="16px"
        position={"absolute"}
        left={"50%"}
        top={"20%"}
        zIndex={-1}
      />
      <Image
        src={dot}
        h="27px"
        w="27px"
        position={"absolute"}
        right={"43%"}
        top={"50%"}
        zIndex={-1}
      />
      <Image
        src={dot}
        h="16px"
        w="16px"
        position={"absolute"}
        left={"40%"}
        bottom={"10%"}
        zIndex={-1}
      />
      <Image
        src={dot}
        h="16px"
        w="16px"
        position={"absolute"}
        right={"2%"}
        bottom={"40%"}
        zIndex={-1}
      />
      <Image
        src={dot}
        h="13px"
        w="13px"
        position={"absolute"}
        left={"2%"}
        top={"30%"}
        zIndex={-1}
      />
      <Image
        src={dot}
        h="23px"
        w="23px"
        position={"absolute"}
        right={"22%"}
        bottom={"10%"}
        zIndex={-1}
      />
      <Image
        src={dot}
        position={"absolute"}
        left={"4%"}
        top={"15%"}
        zIndex={-1}
      />
      <Image
        src={dot}
        position={"absolute"}
        left={"15%"}
        bottom={"30%"}
        zIndex={-1}
      />
    </Box>
  );
};

export default Main;
