import React, { useRef, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { COLORS } from "../../assets/themes";
import info from "../../assets/img/information.png";
import stop from "../../assets/img/stop-violence.png";
import violence from "../../assets/img/hate-speech.png";
import digitalLiteracy from "../../assets/img/digital-literacy.png";
import digitalCitizen from "../../assets/img/digital-citizen.png";
import digitalEmpowerment from "../../assets/img/digital-empowerment.png";
import UploadForm from "./Submit/UploadForm";
import IdeasDataForm from "./Submit/IdeaFrom";
import SubmitButtons from "./Submit/SubmitButtons";

const firstStepData = [
  {
    label: "Digital Literacy",
    image: digitalLiteracy,
  },
  {
    label: "Digital Citizenship",
    image: digitalCitizen,
  },
  {
    label: "Digital Empowerment",
    image: digitalEmpowerment,
  },
  {
    label: "Hate Speech",
    image: violence,
  },
  {
    label: "Misinformation",
    image: info,
  },
  {
    label: "Bully and Harassment",
    image: stop,
  },
];

const SubmitForm = () => {
  const types = [1, 2, 3];
  const formRef = useRef();

  const [currentStep, setCurrentStep] = useState(0);
  const [theme, setTheme] = useState(null);
  const [ideaId, setIdeaId] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [tagValues, setTagValues] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    project: "",
    summary: "",
  });

  const [data, setData] = useState([]);

  const handleChange = (event) => {
    setTheme(event.target.value);
  };

  return (
    <Box w="100%" align="center" mt={{ base: "50px", md: "96px" }}>
      <Text
        color={COLORS.secondary}
        fontSize={{ base: "24px", md: "32px", lg: "32px" }}
        lineHeight={{ base: "29px", md: "39px" }}
        fontFamily="Montserrat Bold"
        fontWeight={700}
        mb={"32px"}
      >
        Submit Your Big Idea In 3 Easy Steps
      </Text>
      <Flex
        direction={"row"}
        justify="center"
        align="center"
        maxW="500px"
        fontSize={{ base: "10px", md: "16px", lg: "18px" }}
        lineHeight={{ base: "20px", md: "27px" }}
        fontFamily="Montserrat Bold"
        fontWeight={800}
        color={"#CBCFD9"}
      >
        {types.map((item, index) => {
          const active = index === currentStep;
          return (
            <Flex
              align="center"
              key={index}
              style={{ color: active && COLORS.primary }}
            >
              <Box color={active && COLORS.primary}>
                <Text>Step</Text>
                <Text>{item}</Text>
              </Box>
              {index + 1 !== types.length && (
                <Box
                  w={{ base: "50px", md: "129px" }}
                  mx={{ base: "20px", md: "30px" }}
                  size={2}
                  color={active && COLORS.primary}
                >
                  <hr
                    style={{
                      border: `2px solid`,
                      color: active && COLORS.primary,
                    }}
                  />
                </Box>
              )}
            </Flex>
          );
        })}
      </Flex>
      {currentStep === 0 && (
        <Box width="100%" maxW="480px" mt={{ base: "40px" }} align="start">
          <Text
            color={COLORS.secondary}
            fontSize={{ base: "14px", md: "16px", lg: "18px" }}
            lineHeight={{ base: "22px", md: "27px" }}
            fontFamily="Montserrat"
            fontWeight={500}
            mb={"16px"}
          >
            Choose Entry Theme
          </Text>
          {firstStepData.map((item) => (
            <Flex
              w="100%"
              h={"60px"}
              mb="8px"
              direction="row"
              align="center"
              justify="space-between"
              px="20px"
              key={item.label}
              sx={{
                background: "#FFFFFF",
                border: "1px solid rgba(108, 85, 153, 0.3);",
                boxSizing: "border-box",
                borderRadius: "12px",
              }}
            >
              <Image src={item.image} />
              <Text
                color={COLORS.secondary_black}
                fontSize={{ base: "12px", md: "16px", lg: "16px" }}
                lineHeight={{ base: "20px", md: "24px" }}
                fontFamily="Montserrat"
                fontWeight={400}
              >
                {item.label}
              </Text>
              <input
                type="radio"
                value={item.label}
                checked={item.label === theme}
                onChange={handleChange}
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </Flex>
          ))}
        </Box>
      )}
      {currentStep === 1 && (
        <IdeasDataForm
          formRef={formRef}
          theme={theme}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          setIdeaId={setIdeaId}
          setSubmitting={setSubmitting}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          setData={setData}
        />
      )}
      {currentStep === 2 && (
        <UploadForm
          ideaId={ideaId}
          tagValues={tagValues}
          setTagValues={setTagValues}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
        />
      )}
      <SubmitButtons
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formRef={formRef}
        theme={theme}
        isSubmitting={isSubmitting}
        tagValues={tagValues}
        selectedFile={selectedFile}
        ideaId={ideaId}
        data={data}
        setData={setData}
        setInitialValues={setInitialValues}
        setSubmitting={setSubmitting}
        setTagValues={setTagValues}
        setSelectedFile={setSelectedFile}
      />
    </Box>
  );
};

export default SubmitForm;
