import { useState, useEffect, useCallback } from "react";
import { getAllWinners } from "../../utils/calls/winners.calls";

function useFetchWinners(page, query = null) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const getWinners = useCallback(async () => {
    try {
      setLoading(true);
      setError(false);
      const res = await getAllWinners([]);
      setTotal(res.data.total);
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      setError(err);
    }
  }, [page, query]);

  useEffect(() => {
    getWinners();
  }, []);

  return { loading, error, data, total };
}

export default useFetchWinners;
