import React from "react";
import { Box, Image, Flex } from "@chakra-ui/react";
import cchubLogo from "../../assets/img/Cchub Logo.png";
// import ja from "../../assets/img/JA Centennial logos-colors-Nigeria.png";
import nerdsFactory from "../../assets/img/nerdsFactory.jpg";

export default function SponsorsLogos() {
  return (
    <Flex
      direction={{ md: "row" }}
      wrap={{ base: "wrap", lg: "nowrap" }}
      align="center"
      justify="center"
      w="100%"
      pt={{ base: "20px", md: "50px", lg: "50px" }}
    >
      {Array.from([cchubLogo, nerdsFactory], (item, idx) => (
        <Box
          w={{ base: "140px", md: "196px", lg: "196px" }}
          key={idx}
          mx={{ base: "20px", lg: "60px" }}
          my={{ base: "20px", lg: 0 }}
        >
          <Image src={item} alt="logo" id="image" />
        </Box>
      ))}
    </Flex>
  );
}
