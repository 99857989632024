import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Image,
} from "@chakra-ui/react";
import success from "../../assets/img/success.gif";
import CopyClipboard from "../Forms/CopyClipboard";

const AfterSubmitModal = ({ isOpen, onClose, url }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="95%" maxW={"786px"} minH="300px">
        <ModalCloseButton
          h="31px"
          w="31px"
          borderRadius={"50%"}
          bg="#ECF0F4"
          p="8px"
          color={"#ADB1B9"}
        />
        <ModalBody minH="300px">
          <Flex
            w="100%"
            align="center"
            justify="center"
            minH="500px"
            direction="column"
          >
            <Image
              src={success}
              w={{ base: "100px", md: "190px" }}
              h={{ base: "100px", md: "190px" }}
              mt="5px"
            />
            <Text
              fontFamily="Montserrat Bold"
              fontWeight={600}
              fontSize={"24px"}
              lineHeight={"31px"}
            >
              Entry submitted successfully
            </Text>
            <Text
              fontFamily="Montserrat"
              fontWeight={400}
              fontSize={"18px"}
              lineHeight={"24px"}
              mt="16px"
            >
              This is your voting link, Copy and share to get votes
            </Text>
            <CopyClipboard text={url} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AfterSubmitModal;
