import React from "react";

import Main from "../components/sections/Main";
import Sponsors from "../components/sections/Sponsors";
import About from "../components/sections/About";
import Submit from "../components/sections/Submit";
import Prize from "../components/sections/Prizes";
import PrevWinners from "../components/sections/PreviousWinners";
import Winners from "../components/sections/Winners";
import WinnersList from "../pages/winners/index";
import WinnersAnnouncement from "../components/sections/WinnersAnnouncement";

export default function Landing() {
  // when winners are announced
  return (
    <>
      <WinnersAnnouncement />
      <Winners />
      <WinnersList />
      <Sponsors />
    </>
  );

  // // before winners are announced
  // return (
  //   <>
  //     <Main />
  //     <About />
  //     <Submit />
  //     <Prize />
  //     <PrevWinners />
  //     <Sponsors />
  //   </>
  // );
}
