import { extendTheme } from "@chakra-ui/react";

const colors = {
  primary: "#00A3FF",
  gray: "#3B3E3F",
  footerText: "##374957",
  green: "#198754",
  grayDivider: "rgba(38, 50, 56, 0.5)",
};

const fonts = {
  heading: "Open Sans",
  body: "Open Sans",
};

const customTheme = extendTheme({ colors, fonts });

export default customTheme;
