import {
    Box,
    Container,
    Flex,
    Image,
    ListItem,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { COLORS } from "../../assets/themes";
import trophy from "../../assets/img/prize 1.png";


const Winners = () => {

    return (
        <Container
            maxW="container.xxl"
            position="relative"
            id="winners"
        >
            <Flex
                w="100%"
                maxW={"1400px"}
                align="center"
                justify={"center"}
                py={{ base: "50px", lg: "100px" }}
                px={{ base: "20px", lg: "124px" }}
                mx="auto"
                zIndex={200}
            >
                <Image src={trophy} w="29.61px" mr="14px" transform={"matrix(0.92, 0.39, -0.4, 0.92, 0, 0)"} />
                <Text
                    color={COLORS.black}
                    fontSize={"32px"}
                    lineHeight={"32px"}
                    fontFamily="Montserrat"
                    fontWeight={700}

                >
                    Winners
                </Text>
            </Flex>
        </Container>
    )
}

export default Winners;