import React, { useState } from "react";

import {
  Input,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { COLORS } from "../../assets/themes";

export const CustomInput = ({
  label,
  width,
  handleInput,
  value,
  required,
  isDisabled,
  isError,
  errorMessage,
  name,
  onBlur,
}) => {
  return (
    <FormControl isRequired={required} width={width} isInvalid={isError}>
      <FormLabel
        color={COLORS.primary}
        fontSize={{ base: "14px", md: "18px", lg: "18px" }}
        lineHeight={{ base: "20px", md: "27px" }}
        fontFamily="Montserrat"
        fontWeight={500}
        marginTop={"24px"}
      >
        {label}
      </FormLabel>
      <Input
        fontFamily="DM Sans, sans-serif"
        value={value}
        onChange={(e) => handleInput(e.target.value)}
        type="text"
        focusBorderColor={COLORS.secondary}
        isDisabled={isDisabled}
        size="lg"
        bg={COLORS.white}
        name={name}
        onBlur={onBlur}
      />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export const CustomTextArea = ({
  label,
  width,
  handleInput,
  value,
  required,
  isDisabled,
  isError,
  errorMessage,
  name,
  onBlur,
}) => {
  const [count, setCount] = useState(0);

  return (
    <FormControl isRequired={required} width={width} isInvalid={isError}>
      <FormLabel
        color={COLORS.primary}
        fontSize={{ base: "14px", md: "18px", lg: "18px" }}
        lineHeight={{ base: "20px", md: "27px" }}
        fontFamily="Montserrat"
        fontWeight={500}
        marginTop={"24px"}
      >
        {label}
      </FormLabel>
      <Textarea
        fontFamily="DM Sans, sans-serif"
        value={value}
        onChange={(e) => {
          handleInput(e.target.value);
          setCount(e.target.value.length);
        }}
        type="text"
        focusBorderColor={COLORS.secondary}
        isDisabled={isDisabled}
        size="lg"
        bg={COLORS.white}
        maxLength={260}
        name={name}
        onBlur={onBlur}
      />
      {errorMessage ? (
        <FormErrorMessage fontFamily="Montserrat">
          {errorMessage}
        </FormErrorMessage>
      ) : (
        <FormHelperText
          color={COLORS.secondary_black}
          fontSize={{ base: "12px" }}
          lineHeight={{ base: "18px" }}
          fontFamily="Montserrat"
          fontWeight={300}
        >
          {260 - count} characters left
        </FormHelperText>
      )}
    </FormControl>
  );
};
