import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { COLORS } from "../../../assets/themes";
import { uploadIdea } from "../../../utils/calls/submit.calls";
import CustomButton from "../../buttons";
import { ArrowLeft, ArrowRight } from "../../Icons";
import AfterSubmitModal from "../../modals/AfterSubmitModal";

const SubmitButtons = ({
  currentStep,
  setCurrentStep,
  formRef,
  theme,
  isSubmitting,
  tagValues,
  selectedFile,
  data,
  setData,
  setInitialValues,
  setSelectedFile,
  setTagValues,
  setSubmitting,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [url, setUrl] = useState(null);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleUploadSubmit = () => {
    setSubmitting(true);

    if (!selectedFile) {
      toast({
        title: "Please select a file to upload",
        isClosable: true,
        description: "",
        status: "error",
        duration: 4000,
        position: "top",
      });
      setSubmitting(false);
      return;
    }

    if (!tagValues.length) {
      toast({
        title: "Please select a category",
        isClosable: true,
        description: "",
        status: "error",
        duration: 4000,
        position: "top",
      });
      setSubmitting(false);
      return;
    }

    const UpdatedData = new FormData();
    UpdatedData.append("file", selectedFile);

    const params = {
      categories: tagValues,
      ...data,
    };

    uploadIdea(UpdatedData, params)
      .then((res) => {
        const copyUrl =
          process.env.REACT_APP_FRONTEND_URL + `ideas/${res?.data?.data?._id}`;

        setUrl(copyUrl);
        onOpen();
        setCurrentStep(0);
        setSubmitting(false);
        setInitialValues({
          name: "",
          project: "",
          summary: "",
        });
        setData([]);
        setTagValues([]);
        setSelectedFile(null);
      })
      .catch((err) => {
        let errorMessage = "Something went wrong, Please try again later.";
        if (err.response?.status !== 500) {
          errorMessage = err.response?.data?.error;
        }
        toast({
          title: errorMessage,
          isClosable: true,
          description: "",
          status: "error",
          duration: 4000,
          position: "top",
        });
        setSubmitting(false);
      });
  };
  const handleNext = () => {
    if (theme) {
      setCurrentStep(currentStep + 1);
    } else {
      toast({
        title: "Please select a theme",
        isClosable: true,
        description: "",
        status: "error",
        duration: 4000,
        position: "top",
      });
    }
  };

  const firstStepActive = currentStep === 0;
  const secondStepActive = currentStep === 1;
  const finalStep = currentStep === 2;
  return (
    <Flex
      width="100%"
      maxW="480px"
      mt="50px"
      justify={{
        base: "center",
        md: firstStepActive ? "center" : "space-between",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
    >
      {(finalStep || secondStepActive) && (
        <CustomButton
          label="Previous"
          active={secondStepActive}
          onClick={() => setCurrentStep(currentStep - 1)}
          leftIcon={
            <ArrowLeft
              color={secondStepActive ? COLORS.white : COLORS.primary}
            />
          }
          isLoading={isSubmitting}
        />
      )}
      {(firstStepActive || secondStepActive) && (
        <CustomButton
          label="Next"
          active={firstStepActive}
          onClick={() => (secondStepActive ? handleSubmit() : handleNext())}
          rightIcon={
            <ArrowRight
              color={firstStepActive ? COLORS.white : COLORS.primary}
            />
          }
          isLoading={isSubmitting}
          loadingText="Submitting"
        />
      )}
      {finalStep && (
        <CustomButton
          label="Submit"
          active={finalStep}
          onClick={() => handleUploadSubmit()}
          isLoading={isSubmitting}
          loadingText="Submitting"
        />
      )}
      <AfterSubmitModal isOpen={isOpen} onClose={onClose} url={url} />
    </Flex>
  );
};

export default SubmitButtons;
