import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Image,
  Stack,
  Tag,
  TagLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { Player, BigPlayButton } from "video-react";
import { COLORS } from "../../assets/themes";
import info from "../../assets/img/information.png";
import stop from "../../assets/img/stop-violence.png";
import violence from "../../assets/img/hate-speech.png";
import digitalLiteracy from "../../assets/img/digital-literacy.png";
import digitalCitizen from "../../assets/img/digital-citizen.png";
import digitalEmpowerment from "../../assets/img/digital-empowerment.png";
import { CopyIconButton, VoteButton } from "../../components/buttons";
import CopyClipboard from "../../components/Forms/CopyClipboard";
import { fetchById } from "../../utils/calls/ideas.calls";
import { Helmet } from "react-helmet-async";
import { getAllVoters, voteIdea } from "../../utils/calls/votes.calls";
import "video-react/dist/video-react.css";
import { calculateTimeLeft } from "../../utils/calculateTimeLeft";
import { v4 as uuidv4 } from "uuid";

const IdeaDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [url, setUrl] = useState(null);
  const [theme, setTheme] = useState(null);
  const [title, setTitle] = useState("");
  const [ip, setIP] = useState(null);
  const [voted, setVoted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [votes, setVotes] = useState(0);
  const [doc, setDoc] = useState();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(true, false));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const getVoters = async () => {
    let deviceId;

    const checkId = localStorage.getItem("deviceId");
    if (checkId) {
      deviceId = checkId;
    } else {
      const newId = uuidv4();
      localStorage.setItem("deviceId", newId);
      deviceId = newId;
    }

    setIP(deviceId);

    getAllVoters()
      .then((res) => {
        const status = res.data?.data.some((el) => el.user_ip === deviceId);
        setVoted(deviceId ? status : true);
        setLoading(false);
      })
      .catch((_err) => {
        setLoading(false);
      });
  };

  const getIdeaById = () => {
    fetchById(id)
      .then((res) => {
        const copyUrl =
          process.env.REACT_APP_FRONTEND_URL + `ideas/${res?.data?.data?._id}`;
        setUrl(copyUrl);
        setData(res?.data?.data);
        setTheme(res?.data?.data?.entry_theme?.name);
        setTitle(res.data?.data?.project_title);
        setDoc(res?.data?.data?.doc);
        setVotes(res?.data?.data?.votes);
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      });
  };

  useEffect(() => {}, [voted]);

  useEffect(() => {
    getIdeaById();
    getVoters();
  }, [id, voted]);

  const handleVoting = () => {
    setSubmitting(true);
    voteIdea({ user_ip: ip }, id)
      .then((_res) => {
        setVotes(votes + 1);
        setSubmitting(false);
        setVoted(true);
        toast({
          title: "Voted",
          isClosable: true,
          description: "",
          status: "success",
          duration: 4000,
          position: "top",
        });
      })
      .catch((_err) => {
        setSubmitting(false);
        toast({
          title: "Something went wrong, please try again later.",
          isClosable: true,
          description: "",
          status: "error",
          duration: 4000,
          position: "top",
        });
      });
  };

  const ThemeComponent = () => {
    let imgSrc = null;

    switch (theme) {
      case "Digital Literacy":
        imgSrc = digitalLiteracy;
        break;
      case "Digital Citizenship":
        imgSrc = digitalCitizen;
        break;
      case "Digital Empowerment":
        imgSrc = digitalEmpowerment;
        break;
      case "Hate Speech":
        imgSrc = violence;
        break;
      case "Misinformation":
        imgSrc = info;
        break;
      case "Bully and Harassment":
        imgSrc = stop;
        break;
      default:
        return null;
    }

    return (
      <HStack mb="25px">
        <Image src={imgSrc} w="24px" mr="14px" />
        <Text
          color={COLORS.secondary_black}
          fontSize={{ base: "12px", md: "16px", lg: "16px" }}
          lineHeight={{ base: "20px", md: "24px" }}
          fontFamily="Montserrat"
          fontWeight={400}
        >
          {theme}
        </Text>
      </HStack>
    );
  };

  return (
    <>
      <Helmet>{title && <title>{title}</title>}</Helmet>
      <Box
        bg={COLORS.white}
        direction="column"
        maxW="container.xl"
        alignSelf={"center"}
        w={{ base: "100%", lg: "90%" }}
        p={8}
        pt={{ base: "150px", lg: "200px" }}
      >
        <Flex
          justify={"space-between"}
          direction={{ base: "column", md: "row" }}
        >
          <Box>
            <ThemeComponent />
            <Text
              color={COLORS.black}
              fontSize={{ base: "20px", md: "20px", lg: "32px" }}
              lineHeight={{ base: "28px", md: "48px" }}
              fontFamily="Montserrat"
              fontWeight={400}
            >
              {data?.project_title}
            </Text>
            <Text
              color={COLORS.black}
              fontSize={{ base: "20px", md: "20px", lg: "32px" }}
              lineHeight={{ base: "28px", md: "48px" }}
              fontFamily="Montserrat"
              fontWeight={500}
              my="16px"
            >
              {data?.name_of_school}
            </Text>
            <Flex maxW="450px" flexWrap="wrap">
              {data?.doc &&
                data.doc?.entry_category.map((label) => (
                  <Tag
                    key={label}
                    m={2}
                    variant="outline"
                    sx={{ border: " 1px solid rgba(108, 85, 153, 0.3);" }}
                    borderRadius="29px"
                  >
                    <TagLabel
                      color={COLORS.secondary}
                      fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                      lineHeight={{ base: "14px", md: "16px" }}
                      fontFamily="Montserrat"
                      fontWeight={400}
                    >
                      {label}
                    </TagLabel>
                  </Tag>
                ))}
            </Flex>
          </Box>
          <Flex
            direction={{ base: "row", md: "row" }}
            mt="20px"
            justify={{ base: "space-between", md: "start" }}
          >
            <Box>
              <Text
                color={COLORS.black}
                fontSize={{ base: "25px", md: "30px", lg: "36px" }}
                lineHeight={{ base: "35px", md: "54px" }}
                fontFamily="Montserrat"
                fontWeight={600}
              >
                {votes || 0}
              </Text>
              <Text
                color="rgba(0, 0, 0, 0.51)"
                fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                lineHeight={{ base: "20px", md: "27px" }}
                fontFamily="Montserrat"
                fontWeight={600}
              >
                {votes > 1 ? "Votes" : "Vote"}
              </Text>
            </Box>
            <Stack ml={{ base: 0, md: "51px" }}>
              <VoteButton
                label={voted && !loading ? "Voted" : "Vote"}
                disabled={voted || !timeLeft?.left || loading}
                onClick={handleVoting}
                isLoading={submitting}
                loadingText="Submitting"
              />
              <CopyIconButton link={url} />
            </Stack>
          </Flex>
        </Flex>
        <Box
          width={{ base: "100%", md: "600px", lg: "924px" }}
          height={{ base: "350px", md: "450px", lg: "679px" }}
          align="center"
          justifyContent={"center"}
          bg="#C4C4C4"
          mt="30px"
        >
          {doc && doc?.mimeType.includes("video") && (
            <Player fluid={false} width="100%" height="100%">
              <source src={doc?.link} />
              <BigPlayButton position="center" />
            </Player>
          )}
          {doc && doc?.mimeType.includes("image") && (
            <Image
              fluid={false}
              width="100%"
              height="100%"
              src={doc.link}
              fit="contain"
            />
          )}
        </Box>
        <Text
          color={COLORS.secondary_black}
          fontSize={{ base: "14px", md: "18px", lg: "18px" }}
          lineHeight={{ base: "20px", md: "27px" }}
          fontFamily="Montserrat"
          fontWeight={400}
          my="48px"
          w={{ base: "100%", md: "80%" }}
          maxW="700px"
        >
          {data?.project_summary}
        </Text>
        <CopyClipboard text={url} />
        <VoteButton
          label={voted && !loading ? "Voted" : "Vote"}
          mb="200px"
          mt="48px"
          disabled={voted || !timeLeft?.left || loading}
          onClick={handleVoting}
          isLoading={submitting}
          loadingText="Submitting"
        />
      </Box>
    </>
  );
};

export default IdeaDetails;
