import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import LandingLayout from "./components/layouts/LandingLayout";
import IdeasComponent from "./pages/Ideas";
import IdeaDetails from "./pages/Ideas/IdeaDetails";

import Landing from "./pages/Landing";
import ScrollToTop from "./utils/ScrollTop";

export default function App() {
  console.error = function () {};
  console.warn = function () {};
  return (
    <LandingLayout>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/ideas" element={<IdeasComponent />} />
            <Route path="/ideas/:id" element={<IdeaDetails />} />
            <Route path="*" element={<Landing />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </LandingLayout>
  );
}
