import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { COLORS } from "../../../assets/themes";
import { TagsButton } from "../../buttons";

const UploadForm = ({
  tagValues,
  setTagValues,
  setSelectedFile,
  selectedFile,
}) => {
  const fileRef = useRef();
  const toast = useToast();

  const handleChange = (e) => {
    const maxSize = 25000000;
    const file = e.target.files[0];
    if (file?.size > maxSize) {
      toast({
        title: "the file exceeds the 25mb limit",
        isClosable: true,
        description: "",
        status: "error",
        duration: 4000,
        position: "top",
      });
      return;
    }
    setSelectedFile(file);
  };

  const uploadedColor = selectedFile?.size ? COLORS.white : "#333333";

  return (
    <Flex
      width="100%"
      maxW="480px"
      mt={{ base: "40px" }}
      direction="column"
      align="start"
    >
      <Text
        color={COLORS.secondary}
        fontSize={{ base: "14px", md: "16px", lg: "18px" }}
        lineHeight={{ base: "22px", md: "27px" }}
        fontFamily="Montserrat"
        fontWeight={500}
        mb={"16px"}
      >
        Upload your Idea ( Jpg / Png / mp4 / PDF)
      </Text>
      <Button
        onClick={() => fileRef.current.click()}
        variant="solid"
        fontFamily="Montserrat"
        fontWeight={400}
        width={"100%"}
        fontSize={"16px"}
        lineHeight={"20px"}
        bg={selectedFile?.size ? COLORS.primary : COLORS.white}
        color={uploadedColor}
        borderColor={uploadedColor}
        borderWidth={"1px"}
        py={"16px"}
        px={"57px"}
        my="10px"
        borderRadius={"10px"}
        _hover={{
          opacity: 0.9,
          color: uploadedColor,
        }}
      >
        {selectedFile?.name ? selectedFile?.name : "Upload"}
      </Button>
      <input
        ref={fileRef}
        onChange={handleChange}
        multiple={false}
        type="file"
        accept="video/*, image/*, .doc, .docx, .pdf"
        hidden
      />
      <Text
        color={COLORS.secondary_black}
        fontSize={{ base: "12px" }}
        lineHeight={{ base: "18px" }}
        fontFamily="Montserrat"
        fontWeight={300}
      >
        Maximum file size 25mb, maximum video length 2 mins
      </Text>
      <Text
        color={COLORS.secondary}
        fontSize={{ base: "14px", md: "16px", lg: "18px" }}
        lineHeight={{ base: "22px", md: "27px" }}
        fontFamily="Montserrat"
        fontWeight={500}
        mt={"50px"}
      >
        Choose Entry Category
      </Text>

      <Flex spacing={4} wrap="wrap">
        {[
          "Jingles",
          "Poems",
          "Artworks",
          "Comics",
          "Drama",
          "Skit",
          "Animated Stories",
        ].map((item) => (
          <TagsButton
            tagValues={tagValues}
            setTagValues={setTagValues}
            label={item}
            key={item}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default UploadForm;
