import { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";
import dataFilter from "../filter";
import { fetchAllIdeas, searchIdeas } from "./ideas.calls";

const isEmptyOrSpaces = (str) => {
  return str === null || str.match(/^ *$/) !== null;
};

function useFetch(page, query = null) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [total, setTotal] = useState(0);

  page === 1 && window.scrollTo(0, 0);

  const getSearchData = useCallback(async () => {
    try {
      const res = await searchIdeas();
      setSearchData(res.data?.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getSearchData();
  }, []);

  const debouncedSearchAPI = debounce(
    async () => {
      const res = dataFilter(searchData, query);
      setData(res);
      setTotal(res.length);
    },
    800,
    { leading: true }
  );

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      setError(false);
      if (isEmptyOrSpaces(query)) {
        const res = await fetchAllIdeas(page);
        setTotal(res.data.total);
        setData((prev) => {
          return [...new Set([...prev, ...res.data?.data])];
        });
      } else {
        await debouncedSearchAPI();
      }

      setLoading(false);
    } catch (err) {
      setError(err);
    }
  }, [page, query]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getList();
    }, 500);

    return () => clearTimeout(timer);
  }, [getList, page]);

  return { loading, error, data, total };
}

export default useFetch;
