import React from "react";
import { Box, Image } from "@chakra-ui/react";
import logo from "../../assets/img/logo.png";

export default function Logo() {
  return (
    <Box w={{ base: "30px", md: "41px", lg: "41px" }}>
      <Image src={logo} alt="logo" />
    </Box>
  );
}
