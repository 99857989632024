import { Box } from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { CustomInput, CustomTextArea } from "../CustomFormFields";

const IdeasDataForm = ({
  formRef,
  theme,
  setCurrentStep,
  currentStep,
  setSubmitting,
  initialValues,
  setInitialValues,
  setData,
}) => {
  const handleSubmit = (values) => {
    setSubmitting(true);
    const data = {
      name_of_school: values?.name,
      project_title: values?.project,
      project_summary: values?.summary,
      entry_theme: theme,
      expiration_date: process.env.REACT_APP_EXPIRATION_DATE,
    };
    setInitialValues(values);
    setData(data);
    setSubmitting(false);
    setCurrentStep(currentStep + 1);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    project: Yup.string().required(),
    summary: Yup.string().required(),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => (
        <Box
          width="100%"
          maxW="480px"
          mt={{ base: "40px" }}
          align="start"
          as="form"
          onSubmit={handleSubmit}
        >
          <CustomInput
            handleInput={handleChange("name")}
            value={values.name}
            width="100%"
            label="Enter Name of School"
            inputType="text"
            isDisabled={false}
            labelFontSize={12}
            isError={errors.name && touched.name}
            errorMessage={errors.name}
            name="name"
            onBlur={handleBlur}
          />
          <CustomInput
            handleInput={handleChange("project")}
            value={values.project}
            width="100%"
            label="Enter Project Title"
            inputType="text"
            isDisabled={false}
            isError={errors.project && touched.project}
            errorMessage={errors.project}
            name="project"
            onBlur={handleBlur}
          />
          <CustomTextArea
            handleInput={handleChange("summary")}
            value={values.summary}
            width="100%"
            label=" Enter Project Summary"
            inputType="text"
            isDisabled={false}
            labelFontSize={12}
            isError={errors.summary && touched.summary}
            errorMessage={errors.summary}
            name="summary"
            onBlur={handleBlur}
          />
        </Box>
      )}
    </Formik>
  );
};

export default IdeasDataForm;
