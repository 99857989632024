import { Box, Container, Image, Text } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/themes";
import frame2 from "../../assets/img/frame2.png";
import Timer from "../Timer.js";
import SubmitForm from "../Forms/SubmitForm";
import { SecondaryButton } from "../buttons";

const Submit = React.forwardRef(() => {
  const [submitOn, setSubmitOn] = useState(false);
  const [voteOn, setVoteOn] = useState(false);
  const formRef = useRef();

  const navigate = useNavigate();

  return (
    <Container
      maxW="container.xxl"
      position="relative"
      bg={COLORS.background}
      id="submit"
    >
      <Box
        bg={COLORS.white}
        position={"absolute"}
        top={0}
        left={0}
        right={0}
        width="100%"
        h={{ base: "20px", md: "50px", lg: "80px" }}
      />
      <Image
        src={frame2}
        position={"absolute"}
        top={0}
        left={0}
        right={0}
        width="100%"
      />
      <Box
        w="100%"
        maxW={"1400px"}
        align="center"
        pt={{ base: "50px", md: "200px" }}
        px={{ base: "20px", lg: "124px" }}
        mx="auto"
        zIndex={200}
      >
        <Timer
          setSubmitOn={setSubmitOn}
          setVoteOn={setVoteOn}
          submitDateEnd={process.env.REACT_APP_SUBMIT_END_DATE}
          voteDateEnd={process.env.REACT_APP_VOTE_END_DATE}
        />
        {submitOn && <SubmitForm formRef={formRef} />}
        {voteOn && !submitOn && (
          <Box w="100%" align="center" mt={{ base: "50px", md: "96px" }}>
            <Text
              color={COLORS.secondary}
              fontSize={{ base: "24px", md: "32px", lg: "32px" }}
              lineHeight={{ base: "29px", md: "39px" }}
              fontFamily="Montserrat Bold"
              fontWeight={700}
              maxW={"700px"}
              mb={"32px"}
            >
              Entry Submissions Closed. To Vote for your favourite Big Idea,
              Click Here:
            </Text>
            <SecondaryButton
              label="VOTE NOW"
              onClick={() => navigate("/ideas")}
            />
          </Box>
        )}
        {!voteOn && !submitOn && (
          <Box w="100%" align="center" mt={{ base: "50px", md: "96px" }}>
            <Text
              color={COLORS.secondary}
              fontSize={{ base: "24px", md: "32px", lg: "32px" }}
              lineHeight={{ base: "29px", md: "39px" }}
              fontFamily="Montserrat Bold"
              fontWeight={700}
              maxW={"700px"}
              mb={"32px"}
            >
              Entry Submissions Closed.
            </Text>
          </Box>
        )}
      </Box>
    </Container>
  );
});

export default Submit;
