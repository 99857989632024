import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { COLORS } from "../../assets/themes";
import Logo from "../ui/Logo";

const MenuItem = ({
  children,
  isLast,
  to = "/",
  external = false,
  ...rest
}) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      fontFamily="Poppins"
      fontWeight={600}
      fontSize={"18px"}
      lineHeight={"27px"}
      color={COLORS.white}
      {...rest}
    >
      {external ? (
        <a rel="noreferrer" target="_blank" href={to}>
          {children}
        </a>
      ) : (
        <a rel="noreferrer" href={to}>
          {children}
        </a>
      )}
    </Text>
  );
};

const Header = (props) => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Box
      bg={COLORS.primary}
      w="100%"
      align="center"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={200}
      pt={4}
      boxShadow="dark-lg"
    >
      <Flex
        as="nav"
        maxW="container.xl"
        alignSelf={"center"}
        wrap="wrap"
        zIndex={200}
        w={{ base: "100%", lg: "90%" }}
        px={8}
        py={"8px"}
        align={"center"}
        justify="space-between"
        bg={COLORS.primary}
        color={["gray", "gray"]}
        {...props}
      >
        <Flex align="center">
          <Logo w="100px" color={["primary", "primary"]} />
        </Flex>
        <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
          {show ? (
            <CloseIcon w={"24px"} h={"24px"} color={COLORS.white} />
          ) : (
            <Flex align="center">
              <Text
                fontFamily="Poppins"
                fontWeight={600}
                fontSize={"18px"}
                lineHeight={"27px"}
                color={COLORS.white}
                mr={2}
              ></Text>
              <HamburgerIcon w={"24px"} h={"24px"} color={COLORS.white} />
            </Flex>
          )}
        </Box>

        <Box
          display={{ base: show ? "block" : "none", md: "block" }}
          flexBasis={{ base: "100%", md: "auto" }}
        >
          <Flex
            align="center"
            justify={["center", "space-between", "flex-end", "flex-end"]}
            direction={["column", "row", "row", "row"]}
            pt={[4, 4, 0, 0]}
          >
            <MenuItem to="/#about">About</MenuItem>
            <MenuItem to="/#winners">Winners</MenuItem>
            <MenuItem to="/#partners">Partners</MenuItem>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
