import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../assets/themes";

function NotFound() {
  const navigate = useNavigate();
  return (
    <Box
      bg={COLORS.white}
      direction="column"
      maxW="container.xl"
      alignSelf={"center"}
      w={{ base: "100%", lg: "90%" }}
      p={8}
      align={"center"}
      pb={{ base: "250px", lg: "500px" }}
    >
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        color={COLORS.secondary}
        fontSize={{ base: "30px", md: "40px", lg: "50px" }}
        lineHeight={{ base: "50px", md: "80px" }}
        fontFamily="Montserrat"
        fontWeight={600}
      >
        404
      </Heading>
      <Text
        mt={3}
        mb={10}
        color={COLORS.secondary_black}
        fontSize={{ base: "18px", md: "25px", lg: "30px" }}
        lineHeight={{ base: "35px", md: "50px" }}
        fontFamily="Montserrat"
        fontWeight={400}
      >
        No results
      </Text>

      <Button
        bg={COLORS.secondary}
        color="white"
        variant="solid"
        onClick={() => navigate("/")}
      >
        Go to Home
      </Button>
    </Box>
  );
}

export default NotFound;
