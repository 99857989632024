import React from "react";
import { Box, Image, Flex } from "@chakra-ui/react";
import winner1 from "../../assets/img/winner1.png";
import winner2 from "../../assets/img/winner2.png";
import winner3 from "../../assets/img/winner3.png";
import winner4 from "../../assets/img/winner4.png";
import winner5 from "../../assets/img/winner5.png";
import winner6 from "../../assets/img/winner6.png";

export default function PrevWinnersImages() {
  const winners = [
    {
      image: winner1,
      name: "Millennium Senior Secondary School, Egbeda, Lagos",
    },
    { image: winner4, name: "Al-Muflihun Private College, Egbeda, Lagos" },
    { image: winner3, name: "State Senior High School Alimosho, Lagos" },
    { image: winner6, name: "King Ado Senior High School, Lagos    " },
    { image: winner5, name: "Ijero Girls’ Junior High School, Lagos" },
    { image: winner2, name: "State Senior High School Alimosho, Lagos" },
  ];
  return (
    <Flex
      direction={{ md: "row" }}
      wrap={{ base: "wrap", lg: "wrap" }}
      align="center"
      justify="center"
      w="100%"
      pt={{ base: "10px", md: "50px", lg: "50px" }}
    >
      {Array.from(winners, (item, idx) => (
        <div className="img_wrap">
          <Box
            key={idx}
            mx={{ base: "10px", lg: "5px" }}
            my={{ base: "10px", lg: "5px" }}
          >
            <Image src={item.image} alt="logo" id="image" />
            <p className="img_description">{item.name}</p>
          </Box>
        </div>
      ))}
    </Flex>
  );
}
