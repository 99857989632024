const calculateTimeLeft = (vote, submit) => {
  let difference = +new Date(process.env.REACT_APP_VOTE_END_DATE) - +new Date();

  let timeLeft = {};

  if (submit) {
    difference = +new Date(process.env.REACT_APP_SUBMIT_END_DATE) - +new Date();
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
      left: difference,
    };
  }

  if (vote && difference > 0 && checkVotingPeriod()) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
      left: difference,
    };
  }

  return timeLeft;
};

const checkVotingPeriod = () => {
  const startDate = new Date(process.env.REACT_APP_SUBMIT_END_DATE);
  const endDate = new Date(process.env.REACT_APP_VOTE_END_DATE);

  if (new Date() > startDate && new Date() <= endDate) {
    return true;
  }

  return false;
};

export { calculateTimeLeft, checkVotingPeriod };
