import React from "react";
import { Text, Flex, IconButton, useToast } from "@chakra-ui/react";
import { COLORS } from "../../assets/themes";
import { CopyIcon } from "../Icons";

const CopyClipboard = ({ text }) => {
  const toast = useToast();

  const copyTextToClipboard = async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    }
  };

  const handleCopyClick = () => {
    const id = "copy-toast";
    copyTextToClipboard()
      .then(() => {
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "Link copied",
            isClosable: true,
            description: "",
            status: "success",
            duration: 4000,
            position: "bottom",
            containerStyle: {
              backgroundColor: "black",
              borderRadius: "10px",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Flex
      w={{ base: "100%", md: "80%" }}
      h={"61px"}
      maxW="586px"
      mt="25px"
      direction="row"
      align="center"
      justify="space-between"
      sx={{
        background: "#ECF0F4",
        borderRadius: "74px",
      }}
    >
      <Text
        color={COLORS.black}
        fontSize={{ base: "12px", md: "14px", lg: "18px" }}
        lineHeight={{ base: "20px", md: "23px" }}
        fontFamily="Montserrat"
        fontWeight={400}
        maxW={{ base: "60%", md: "80%" }}
        ml={{ base: "15px", lg: "42px" }}
      >
        {text}
      </Text>

      <IconButton
        aria-label="Search database"
        icon={<CopyIcon size="40px" />}
        pr={{ base: "7px", md: "7px" }}
        _focus={{
          outline: "none",
        }}
        onClick={handleCopyClick}
      />
    </Flex>
  );
};

export default CopyClipboard;
