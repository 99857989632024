import axios from "axios";

let baseUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchAllIdeas = (page) => {
  let response = axios.get(
    `${baseUrl}/api/v1/idea/views?skip=${page}&limit=12`
  );
  return response;
};

export const fetchById = (id) => {
  let response = axios.get(`${baseUrl}/api/v1/idea/view/${id}`);
  return response;
};

export const searchIdeas = () => {
  let response = axios.get(`${baseUrl}/api/v1/idea/views?limit=100`);
  return response;
};
