import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { COLORS } from "../../assets/themes";
import trophy from "../../assets/img/prize 1.png";
import first from "../../assets/img/1st.png";
import second from "../../assets/img/2nd.png";
import third from "../../assets/img/3rd.png";

const Prize = () => (
  <Container
    maxW="container.xxl"
    position="relative"
    bg={COLORS.background}
    id="prize"
  >
    <Box
      w="100%"
      maxW={"1400px"}
      align="center"
      py={{ base: "50px", md: "100px" }}
      px={{ base: "20px", lg: "124px" }}
      mx="auto"
      zIndex={200}
    >
      <Text
        color={COLORS.secondary}
        fontSize={{ base: "24px", md: "32px", lg: "32px" }}
        lineHeight={{ base: "29px", md: "39px" }}
        fontFamily="Montserrat Bold"
        fontWeight={700}
        mb={"55px"}
      >
        Prize
      </Text>
      <Image src={trophy} />
      <Box
        color={COLORS.black}
        fontSize={{ base: "16px", md: "18px", lg: "18px" }}
        lineHeight={{ base: "20px", md: "27px" }}
        fontFamily="Montserrat"
        fontWeight={400}
        maxW={"566.02px"}
      >
        <Text mt={"32px"}>
          We are on the lookout for creative and engaging entries which could
          take the form of poems, jingles, artworks, animated stories, comics
          and games.
        </Text>
        <Text mt={"5px"}>
          Schools stand a chance to win fantastic prizes including;
        </Text>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        width={"100%"}
        justify={{ base: "center", md: "space-between" }}
        mt={{ base: "43px" }}
        maxW={"746px"}
        fontSize={{ base: "16px", md: "18px", lg: "18px" }}
        lineHeight={{ base: "20px", md: "22px" }}
        fontFamily="Montserrat"
        fontWeight={600}
      >
        <Box my={{ base: "32px", md: 0 }}>
          <Image src={first} />
          <Text maxW={"223px"} mt={"16px"}>
            Interactive White Board
          </Text>
        </Box>
        <Box>
          <Image src={second} />
          <Text maxW={"223px"} mt={"16px"}>
            Oculus Quest 2
          </Text>
        </Box>
        <Box>
          <Image src={third} />
          <Text maxW={"223px"} mt={"16px"}>
            LED HD Projector
          </Text>
        </Box>
      </Flex>
    </Box>
  </Container>
);

export default Prize;
