import React, { useEffect } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { COLORS } from "../../assets/themes";
import { ShareIcon } from "../Icons";

const CustomButton = ({ label, active, onClick, ...props }) => (
  <Button
    variant="solid"
    fontFamily="Poppins"
    fontWeight={400}
    fontSize={"18px"}
    lineHeight={"27px"}
    bg={active ? COLORS.primary : COLORS.white}
    color={active ? COLORS.white : COLORS.primary}
    borderColor={COLORS.primary}
    borderWidth={"1px"}
    py={"16px"}
    px={"57px"}
    my="10px"
    borderRadius={"10px"}
    _hover={{
      opacity: 0.9,
      color: active ? COLORS.white : COLORS.primary,
    }}
    onClick={onClick}
    {...props}
  >
    {label}
  </Button>
);

export const SecondaryButton = ({ label, onClick, ...props }) => (
  <Button
    variant="solid"
    fontFamily="Poppins"
    fontWeight={400}
    fontSize={"18px"}
    lineHeight={"27px"}
    bg={COLORS.primary}
    color={COLORS.white}
    py={"16px"}
    px={"57px"}
    my="10px"
    borderRadius={"10px"}
    _hover={{
      opacity: 0.9,
      color: COLORS.white,
    }}
    onClick={onClick}
    {...props}
  >
    {label}
  </Button>
);

export const TagsButton = ({ tagValues, setTagValues, label, ...props }) => {
  let selected = tagValues.includes(label);

  useEffect(() => {}, [tagValues.length, selected]);

  const handleClick = () => {
    const index = tagValues.indexOf(label);
    if (index > -1) {
      setTagValues(tagValues.filter((item) => item !== label));
    } else {
      setTagValues([...tagValues, label]);
    }
  };

  return (
    <Button
      variant="solid"
      fontFamily="Montserrat"
      fontWeight={400}
      fontSize={"12px"}
      lineHeight={"15.6px"}
      bg={selected ? COLORS.primary : COLORS.white}
      color={selected ? COLORS.white : COLORS.primary}
      borderColor={COLORS.primary}
      borderWidth={"1px"}
      height={{ base: "32px" }}
      m="5px"
      borderRadius={"29px"}
      _hover={{
        opacity: 0.7,
      }}
      onClick={() => handleClick()}
      {...props}
    >
      {label}
    </Button>
  );
};

export const VoteButton = ({ label, onClick, ...props }) => (
  <Button
    variant="solid"
    fontFamily="Poppins"
    fontWeight={400}
    fontSize={{ base: "16px", md: "24px" }}
    lineHeight={{ base: "20x", md: "31px" }}
    bg="#67B71D"
    color={COLORS.white}
    py={"18px"}
    px={"57px"}
    my="10px"
    borderRadius={"12px"}
    _hover={{
      opacity: 0.9,
      color: COLORS.white,
    }}
    onClick={onClick}
    {...props}
  >
    {label}
  </Button>
);

export const CopyIconButton = ({ link }) => {
  const toast = useToast();
  const copyTextToClipboard = async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(link);
    }
  };

  const handleCopyClick = () => {
    const id = "copy-toast";
    copyTextToClipboard()
      .then(() => {
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "Link copied",
            isClosable: true,
            description: "",
            status: "success",
            duration: 4000,
            position: "top-right",
            containerStyle: {
              backgroundColor: "black",
              borderRadius: "10px",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Button
      rightIcon={<ShareIcon />}
      variant="ghost"
      color="rgba(0, 0, 0, 0.51)"
      fontSize={{ base: "10px", md: "16px", lg: "18px" }}
      lineHeight={{ base: "20px", md: "27px" }}
      fontFamily="Montserrat"
      fontWeight={600}
      _focus={{
        outline: "none",
      }}
      onClick={handleCopyClick}
    >
      COPY LINK
    </Button>
  );
};

export const ViewButton = ({ label, onClick, ...props }) => (
  <Button
    variant="outline"
    fontFamily="Poppins"
    fontWeight={400}
    fontSize={{ base: "8px", md: "12px" }}
    lineHeight={{ base: "12x", md: "15.6px" }}
    color={COLORS.secondary_black}
    h="32px"
    px={"16px"}
    borderRadius={"4px"}
    _hover={{
      opacity: 0.9,
      color: COLORS.secondary_black,
    }}
    onClick={onClick}
    {...props}
  >
    {label}
  </Button>
);

export default CustomButton;
