import React, { useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Instagram } from "react-content-loader";
import { COLORS } from "../../assets/themes";
import { Helmet } from "react-helmet-async";
import Card from "./Card";
import NotFound from "../../components/NotFound";
import { SearchIcon } from "@chakra-ui/icons";
import useFetch from "../../utils/calls/useFetch";
import InfiniteScroll from "react-infinite-scroll-component";

const IdeaComponent = () => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const { loading, data, total } = useFetch(page, query);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Ideas</title>
      </Helmet>
      <Box
        bg={COLORS.white}
        direction="column"
        maxW="container.xl"
        alignSelf={"center"}
        w={{ base: "100%", lg: "1400px" }}
        p={{ base: 4, lg: 8 }}
        pt={{ base: "200px", lg: "200px" }}
      >
        <Flex alignItems={"flex-end"} justifyContent="flex-end" mb="54px">
          <InputGroup maxWidth="376px" height={"52px"} alignItems={"center"}>
            <InputRightElement
              pointerEvents="none"
              height={"52px"}
              mr="10px"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              type="text"
              placeholder="Search School or Project"
              height={"52px"}
              borderWidth={0}
              bg="rgba(51, 51, 51, 0.1)"
              alignItems={"center"}
              borderRadius="29px"
              onChange={handleInputChange}
            />
          </InputGroup>
        </Flex>
      </Box>
      {!data.length && loading && (
        <Box
          bg={COLORS.white}
          direction="column"
          maxW="container.xl"
          alignSelf={"center"}
          w={{ base: "100%", lg: "1400px" }}
          p={{ base: 4, lg: 8 }}
          pb={{ base: "150px", lg: "150px" }}
        >
          <Instagram />
        </Box>
      )}
      {data.length > 0 && (
        <Box
          bg={COLORS.white}
          direction="column"
          maxW="container.xl"
          alignSelf={"center"}
          w={{ base: "100%", lg: "1400px" }}
          p={{ base: 4, lg: 8 }}
        >
          <Text
            color={COLORS.black}
            fontSize={{ base: "24px", md: "32px", lg: "32px" }}
            lineHeight={{ base: "29px", md: "39px" }}
            fontFamily="Montserrat Bold"
            fontWeight={700}
            maxW={"800px"}
          >
            Vote for your favorite Big Idea from the Safe Online Fest Contest
          </Text>
          <InfiniteScroll
            dataLength={data.length}
            next={() => data.length > 11 && setPage((prev) => prev + 1)}
            hasMore={total > data?.length}
            loader={<Text p={{ base: 4, lg: 8 }}>Loading...</Text>}
          >
            <SimpleGrid columns={[1, 1, 2, 3]}>
              {data.map((item, idx) => (
                <Card key={item + idx} data={item} />
              ))}
            </SimpleGrid>
          </InfiniteScroll>
        </Box>
      )}
      {!data.length && !loading && <NotFound />}
    </>
  );
};

export default IdeaComponent;
