import {
  Box,
  Container,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { COLORS } from "../../assets/themes";
import danger from "../../assets/img/danger.png";
import stop from "../../assets/img/stop.png";
import info from "../../assets/img/info.png";
import digitalEmpowerment from "../../assets/img/DE.png";
import digitalLiteracy from "../../assets/img/DL.png";
import digitalCitizen from "../../assets/img/DC.png";
import flyer from "../../assets/img/2023_flyer.jpg";

const WinnersAnnouncement = () => (
  <Container
    maxW={"1400px"}
    direction={{ base: "column", lg: "row" }}
    align="center"
    justify={{ base: "center", lg: "center" }}
    py={{ base: "50px", lg: "50px" }}
    px={{ base: "20px", lg: "124px" }}
    mx="auto"
    zIndex={100}
    position="relative"
  >
    <Box
      align="start"
      position="relative"
      maxWidth="650px"
      color={COLORS.black}
      fontSize={{ base: "12px", md: "18px", lg: "18px" }}
      lineHeight={{ base: "18px", md: "27px" }}
      fontFamily="Montserrat"
      fontWeight={500}
      pt={{ base: "150px", lg: "100px" }}
      zIndex={1}
    >
      <Text
        color={COLORS.black}
        fontSize={{ base: "24px", md: "32px", lg: "32px" }}
        lineHeight={{ base: "29px", md: "39px" }}
        fontFamily="Montserrat Bold"
        fontWeight={700}
        mb={"32px"}
      >
        Safe Online Fest Contest WINNERS!!!
      </Text>
      <Image src={flyer} mb={"30px"} />
      <Text mb={"30px"}>
        We are pleased to announce the winners of the Big Ideas Submissions for
        the Safe Online Fest 2022.
      </Text>
      <Text mb={"30px"}>
        The competition organised as the close-out event of the Safe Online with
        Meta 2022 campaign was designed to engage with schools on various ways
        they can use the internet safely and responsibly. Since 2019, Meta has
        organised a country-wide Safe Online Program Managed by Co-Creation Hub
        Nigeria in partnership with NerdzFactory. The program has trained 75,107
        students, teachers, school leaders and youths.
      </Text>
      <Text mb={"30px"}>
        The fest challenged schools that participated in the training in 2022 to
        come up with Big Ideas on making Digital Literacy and Online Safety
        education exciting and engaging for young people using a variety of
        artistic expressions such as dramas, poems, jingles, artworks, animated
        stories, comics and games.
      </Text>
      <Text mb={"30px"}>
        The submissions captured expressions of learners’ understanding of any
        of the six themes taught during the Safe Online 2022 training in their
        schools:
      </Text>
      <UnorderedList pl="10px" fontWeight={600} mb={"30px"}>
        <ListItem>Hate Speech</ListItem>
        <ListItem>Misinformation</ListItem>
        <ListItem>Bullying and Harassment</ListItem>
        <ListItem>Digital Literacy</ListItem>
        <ListItem>Digital Citizenship</ListItem>
        <ListItem>Digital Empowerment</ListItem>
      </UnorderedList>
      <Text mb={"30px"}>
        A total of 35 Big Ideas were received from across Nigeria and rigorously
        evaluated using the following criteria
      </Text>
      <UnorderedList pl="10px" fontWeight={600} mb={"30px"}>
        <ListItem>
          {" "}
          Originality, creativity and level of engagement (20 points)
        </ListItem>
        <ListItem>
          Clarity of the Big Idea to any of the six themes (40 points)
        </ListItem>
        <ListItem>Number of Votes (40 points)</ListItem>
      </UnorderedList>
      <Text mb={"30px"}>
        The highest-scoring Big Ideas determined the winners in each category.
      </Text>
      <Text mb={"30px"}>
        Six schools came first and second place in the Digital Literacy,
        Bullying & Harassment and Misinformation Categories. Unfortunately, none
        of the entries for the ‘Hate Speech, Digital Empowerment and Digital
        Citizenship’ categories spoke to the theme.
      </Text>
    </Box>
  </Container>
);

export default WinnersAnnouncement;
