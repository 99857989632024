import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import customTheme from "./utils/theme";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ChakraProvider theme={customTheme}>
        <App />
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>,
  rootElement
);
