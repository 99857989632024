import {
  Box,
  Container,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { COLORS } from "../../assets/themes";
import danger from "../../assets/img/danger.png";
import stop from "../../assets/img/stop.png";
import info from "../../assets/img/info.png";
import digitalEmpowerment from "../../assets/img/DE.png";
import digitalLiteracy from "../../assets/img/DL.png";
import digitalCitizen from "../../assets/img/DC.png";

const About = () => (
  <Container
    maxW="container.xxl"
    position="relative"
    bg={COLORS.white}
    id="about"
  >
    <Flex
      w="100%"
      maxW={"1400px"}
      direction={{ base: "column", lg: "row" }}
      align="center"
      justify={{ base: "center", lg: "space-between" }}
      py={{ base: "50px", lg: "100px" }}
      px={{ base: "20px", lg: "124px" }}
      mx="auto"
      zIndex={100}
      position="relative"
    >
      <Box
        maxWidth="650px"
        color={COLORS.black}
        fontSize={{ base: "12px", md: "18px", lg: "18px" }}
        lineHeight={{ base: "18px", md: "27px" }}
        fontFamily="Montserrat"
        fontWeight={500}
      >
        <Text
          color={COLORS.secondary}
          fontSize={{ base: "24px", md: "32px", lg: "32px" }}
          lineHeight={{ base: "29px", md: "39px" }}
          fontFamily="Montserrat Bold"
          fontWeight={700}
          mb={"32px"}
        >
          Safe Online Fest
        </Text>
        <Text mb={"30px"}>
          Since 2019, Meta has organised a country-wide campaign managed by
          Co-Creation Hub Nigeria in partnership NerdzFactory to curtail the
          associated risks with teaching and learning online for students,
          teachers, school leaders and youths. Through the programme, we have
          trained, over 50,000 people on digital literacy and online safety.
        </Text>
        <Text mb={"30px"}>
          To close out the 2022 campaign, we are organizing the Safe Online Fest
          which challenges learners across Nigeria to apply creative ideas in
          educating other young people, about how to use the internet safely and
          responsibly. Participants are required to submit a <b>big idea</b>{" "}
          that could take the form of artwork, animated stories, comics, games,
          jingles, drama, and posters. In addition, the entry should be focused
          on any one of the six themes of the program;
        </Text>
        <UnorderedList pl="10px" fontWeight={600} mb={"30px"}>
          <ListItem>Digital Literacy</ListItem>
          <ListItem>Digital Citizenship</ListItem>
          <ListItem>Digital Empowerment</ListItem>
          <ListItem>Hate speech</ListItem>
          <ListItem>Misinformation</ListItem>
          <ListItem>Bullying and Harassment</ListItem>
        </UnorderedList>
        <Text mb={"30px"}>
          <b>Eligibility Criteria</b>: The Safe Online Fest is open to schools
          that have participated in Safe Online 101 and Online Safety for School
          Leaders and Teachers training
        </Text>
        <Text mb={"15px"}>
          <b>Selection Criteria</b>
        </Text>
        <UnorderedList pl="10px" fontWeight={300} mb={"30px"}>
          <ListItem>
            <b>Creativity and originality (20%)</b>: The concept behind the
            content matters a lot. Although learners have the chance to be
            inspired by ideas on the internet, any form of plagiarism will be
            penalised. In addition, an adult can monitor the school’s project,
            as ideas that highly represent insights from learners will be
            appreciated.
          </ListItem>
          <ListItem>
            <b>Clarity of theme (40%)</b>: Ideas will be judged on how well they
            portray any of the themes listed above, and how easy it is to
            understand the concept.
          </ListItem>
          <ListItem>
            <b>Number of votes (40%)</b>: Ideas with a high number of votes
            stand a better chance of winning.
          </ListItem>
        </UnorderedList>
        <Text mb={"30px"}>
          <b>Sample ideas to consider</b>:
        </Text>
        <UnorderedList pl="10px" fontWeight={400}>
          <ListItem>Don’t give out personal information online</ListItem>
          <ListItem>
            Create strong passwords which are not shared with anyone
          </ListItem>
          <ListItem>
            Tell a trusted adult about cyberbullying and online anti-social
            behaviour
          </ListItem>
          <ListItem>Report unwanted contact from strangers</ListItem>
          <ListItem>Change settings to private or ‘Friends & Family’</ListItem>
        </UnorderedList>
      </Box>
      <Image
        src={danger}
        top={"10%"}
        right={"5%"}
        zIndex={-1}
        position={"absolute"}
        display={{ base: "none", lg: "flex" }}
      />
      <Image
        src={info}
        position={"absolute"}
        top={"25%"}
        right={"20%"}
        zIndex={-1}
        display={{ base: "none", lg: "flex" }}
      />
      <Image
        src={stop}
        position={"absolute"}
        top={"40%"}
        right={"5%"}
        zIndex={-1}
        display={{ base: "none", lg: "flex" }}
      />

      <Image
        src={digitalLiteracy}
        position={"absolute"}
        top={"55%"}
        right={"20%"}
        zIndex={-1}
        display={{ base: "none", lg: "flex" }}
      />

      <Image
        src={digitalCitizen}
        position={"absolute"}
        top={"70%"}
        right={"5%"}
        zIndex={-1}
        display={{ base: "none", lg: "flex" }}
      />

      <Image
        src={digitalEmpowerment}
        position={"absolute"}
        top={"85%"}
        right={"20%"}
        zIndex={-1}
        display={{ base: "none", lg: "flex" }}
      />
    </Flex>
  </Container>
);

export default About;
