import React from "react";
import { Flex } from "@chakra-ui/react";
import Footer from "../sections/Footer";
import Header from "../Header";
import WinnersAnnouncementHeader from "../sections/WinnersAnnouncementHeader";
import WinnersAnnouncementFooter from "../sections/WinnersAnnouncementFooter";

export default function LandingLayout(props) {
  // when winners are announced
  return (
    <Flex
      direction="column"
      align="center"
      w={{ xl: "100%" }}
      m="0 auto"
      {...props}
    >
      <WinnersAnnouncementHeader />
      {props.children}
      <WinnersAnnouncementFooter />
    </Flex>
  );

  // // before winners are announced
  // return (
  //   <Flex
  //     direction="column"
  //     align="center"
  //     w={{ xl: "100%" }}
  //     m="0 auto"
  //     {...props}
  //   >
  //     <Header />
  //     {props.children}
  //     <Footer />
  //   </Flex>
  // );
}
