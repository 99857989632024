import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  HStack,
  TagLabel,
  Tag,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/themes";
import info from "../../assets/img/information.png";
import stop from "../../assets/img/stop-violence.png";
import violence from "../../assets/img/hate-speech.png";
import digitalLiteracy from "../../assets/img/digital-literacy.png";
import digitalCitizen from "../../assets/img/digital-citizen.png";
import digitalEmpowerment from "../../assets/img/digital-empowerment.png";
import { CopyIconButton, ViewButton } from "../../components/buttons";

const ThemeComponent = ({ theme }) => {
  let imgSrc = null;

  switch (theme) {
    case "Digital Literacy":
      imgSrc = digitalLiteracy;
      break;
    case "Digital Citizenship":
      imgSrc = digitalCitizen;
      break;
    case "Digital Empowerment":
      imgSrc = digitalEmpowerment;
      break;
    case "Hate Speech":
      imgSrc = violence;
      break;
    case "Misinformation":
      imgSrc = info;
      break;
    case "Bully and Harassment":
      imgSrc = stop;
      break;
    default:
      return null;
  }

  return (
    <HStack mb="25px">
      <Image src={imgSrc} w="24px" mr="14px" />
      <Text
        color={COLORS.secondary_black}
        fontSize={{ base: "12px", md: "16px", lg: "16px" }}
        lineHeight={{ base: "20px", md: "24px" }}
        fontFamily="Montserrat"
        fontWeight={400}
      >
        {theme}
      </Text>
    </HStack>
  );
};

function Card({ data }) {
  const navigate = useNavigate();

  const copyUrl = process.env.REACT_APP_FRONTEND_URL + `ideas/${data?._id}`;

  return (
    <Box
      p={4}
      display="flex"
      maxWidth="387px"
      margin="18px"
      bg={COLORS.white}
      borderRadius="8px"
      sx={{ boxShadow: "1px 5px 24px rgba(0, 0, 0, 0.1)" }}
      minH="379px"
      justifyContent={"space-between"}
      flexDirection="column"
    >
      <Box w="100%">
        <Flex justify={{ base: "space-between" }}>
          <ThemeComponent theme={data?.entry_theme?.name} />
          <Box>
            <Text
              color={COLORS.black}
              fontSize={{ base: "25px", md: "30px", lg: "36px" }}
              lineHeight={{ base: "35px", md: "54px" }}
              fontFamily="Montserrat"
              fontWeight={600}
              textAlign="center"
            >
              {data?.votes}
            </Text>
            <Text
              color="rgba(0, 0, 0, 0.51)"
              fontSize={{ base: "14px", md: "16px", lg: "18px" }}
              lineHeight={{ base: "20px", md: "27px" }}
              fontFamily="Montserrat"
              fontWeight={600}
              textAlign="center"
            >
              Votes
            </Text>
          </Box>
        </Flex>
        <Text
          color={COLORS.black}
          fontSize={{ base: "18px", md: "20px", lg: "24px" }}
          lineHeight={{ base: "24px", md: "36px" }}
          fontFamily="Montserrat"
          fontWeight={400}
        >
          {data?.project_title}
        </Text>
        <Text
          color={COLORS.black}
          fontSize={{ base: "14px", md: "18px", lg: "18px" }}
          lineHeight={{ base: "18px", md: "27px" }}
          fontFamily="Montserrat"
          fontWeight={500}
          my="16px"
        >
          {data?.name_of_school}
        </Text>
        <Flex maxW="450px" flexWrap="wrap">
          {data?.doc &&
            data.doc?.entry_category.map((label) => (
              <Tag
                key={label}
                m={2}
                variant="outline"
                sx={{ border: " 1px solid #6C55994D" }}
                borderRadius="29px"
              >
                <TagLabel
                  color={COLORS.secondary_black}
                  fontSize={{ base: "10px", md: "12px", lg: "12px" }}
                  lineHeight={{ base: "14px", md: "16px" }}
                  fontFamily="Montserrat"
                  fontWeight={400}
                >
                  {label}
                </TagLabel>
              </Tag>
            ))}
        </Flex>
      </Box>
      <Flex align={"center"} justify={{ base: "space-between" }}>
        <ViewButton
          label="View Details"
          onClick={() => navigate(`/ideas/${data?._id}`)}
        />
        <CopyIconButton link={copyUrl} />
      </Flex>
    </Box>
  );
}

export default Card;
