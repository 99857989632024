/* eslint-disable array-callback-return */
const stringFilter = (textFilter, textToFilter) => {
  if (textToFilter) {
    return textToFilter
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(",", ".")
      .includes(
        textFilter
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(",", ".")
      );
  } else {
    return false;
  }
};
const numberFilter = (textFilter, numberToFilter) => {
  if (numberToFilter) {
    return numberToFilter
      .toString()
      .toLowerCase()
      .replace(",", ".")
      .includes(textFilter.toLowerCase().replace(",", "."));
  } else {
    return false;
  }
};

const dataFilter = (data, query) =>
  data.filter((o) =>
    Object.keys(o).some((k) => {
      if (k === "id" || k === "_id") return;
      if (typeof o[k] === "string") {
        return stringFilter(query, o[k]);
      } else if (typeof o[k] === "number") {
        return numberFilter(query, o[k]);
      }
    })
  );

export default dataFilter;
