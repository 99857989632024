import axios from "axios";

let baseUrl = process.env.REACT_APP_BACKEND_URL;

export const getAllVoters = () => {
  let response = axios.get(`${baseUrl}/api/v1/vote/voters`);
  return response;
};

export const voteIdea = (data, id) => {
  let response = axios.post(`${baseUrl}/api/v1/vote/${id}`, data);
  return response;
};
