import React, { useState } from "react";
import { SimpleGrid, Text, Box, Container } from "@chakra-ui/react";

import { Helmet } from "react-helmet-async";
import Card from "./WinnersCard";
import NotFound from "../../components/NotFound";
import useFetch from "../winners/useFetchWinners";
import InfiniteScroll from "react-infinite-scroll-component";
import { COLORS } from "../../assets/themes";
import { Instagram } from "react-content-loader";

const WinnerComponent = () => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const { loading, data, total } = useFetch(page, query);

  return (
    <>
      <Helmet>
        <title>Winners</title>
      </Helmet>
      {data.map((item, idx) => (
        <Box
          bg={COLORS.white}
          direction="column"
          maxW="container.xl"
          alignSelf={"center"}
          p={{ base: 4, lg: 8 }}
          fontSize={{ base: "12px", md: "18px", lg: "18px" }}
          lineHeight={{ base: "18px", md: "27px" }}
          fontFamily="Montserrat"
          fontWeight={600}
        >
          <Text mb={"30px"}>{item.theme}:</Text>
          <SimpleGrid columns={[1, 1, 2, 3]}>
            <Card key={item + idx} data={item} />
          </SimpleGrid>
        </Box>
      ))}
    </>
  );
};

export default WinnerComponent;
